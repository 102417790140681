import React, { Component } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCaretDown, faCaretUp, faMinus } from '@fortawesome/free-solid-svg-icons'

import {  OverlayTrigger, Tooltip } from 'react-bootstrap';

import gcolor from '../../../../public/style.scss';

const SCALE = [
  { score :95, rank : "A+" , rate : "Very high"},
  { score : 90, rank  : "A"},
  { score : 85, rank  : "A-" },
  { score : 80, rank  : "B+" },
  { score : 75, rank  : "B"},
  { score : 70, rank  : "B-"},
  { score : 65, rank  : "C+"},
  { score :60, rank  : "C"},
  { score :55, rank  : "C-"},
  { score :50, rank  : "D+", rate : "Middle"},
  { score :45, rank  : "D"},
  { score :40, rank  : "D-"},
  { score :35, rank  : "E+"},
  { score :30, rank  : "E"},
  { score :25, rank  : "E-"},
  { score :20, rank  : "F+"},
  { score :15, rank  : "F"},
  { score :10, rank  : "F-"},
  { score :0, rank  : "F-", rate : "Very Low"},
]
const gd = [
  {  x: "risk_rate" , y: 0 },
  {  x: 'win_rate' , y: 0 },
  {  x: "quantity_rate" , y: 0 },
  {  x: "profit_rate" , y: 0  },
]
const frame = {
  "risk_rate" : 100,
  'win_rate' : 100,
  "quantity_rate" : 100 ,
  "profit_rate" : 100 
}

var t = null

class Card extends Component {

    constructor(props) {
        super(props)

      //console.log( gcolor )

        this.state = { 
          showBoard : false,
          data : gd,
          cdata : gd,
          maxima : frame,
          isOwner : true,
          cash : props.cash,
          profit :props.profit,
        };        

    }

    componentDidMount() {
      var _this = this
      t = setInterval(
        () => {
          var p = _this.state.profit
          p += Math.round(  (Math.random() - 0.5) * _this.props.base * 100 )/100
          _this.setState( { profit : p } )
        }, _this.props.frame
      )
    }

    componentWillUnmount() {
      if(t) {
        clearInterval(t)
      }
    }

    UNSAFE_componentWillReceiveProps(props) {
      
    }

    componentWillUnmount() {
    }

    getRank(score) {

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var s = "F-"
      var maxColor = hexToRgb( "#B51700" )
      var minColor = hexToRgb( "#000000" )

      var obj = { r: 0, g:0, b:0}
      for (var c in obj) {
        obj[c] = Math.round( (maxColor[c] - minColor[c] ) * score/100 + minColor[c] )
      }

      var color = rgbToHex( obj.r, obj.g, obj.b )

      for( var i in SCALE ) {
        if(  score > SCALE[i].score ) {
          s = SCALE[i].rank
          break;
        }
      }
      
      return { rank : s, color : color }
    }
    
    render() {
        var valid = true
        var overdraft = false
        var gain = true
        var lost = false
        var purchased = false
        var onhold = 1
        var recent = true

        var percent = Math.round( this.state.profit / this.props.cash * 10000 )/100

        return (
          <div 
            className={['Card'].join(" ")} >

            <div className={['Upper',
                (!overdraft)?(
                  valid?
                  (  
                    gain?
                      'Gain':
                      (
                        lost?
                        'Lost':"Normal"
                      )
                  ):"Overdraft"
                ):'Overdraft' 
              ].join(" ")}>


              { 
                <div className={ ['name'].join(' ') }>  { this.props.name }  </div>
                
              }
              <div className={['Initial-Holder']}>
                <div className={['TTitle'].join(' ')}> 
                  { "Current Value" } 
                </div>
                <div className={['Value'].join(' ')}>
                  {
                    overdraft?
                    ""
                    :
                    ("$" + 
                      (
                        (this.state.cash + this.state.profit).toLocaleString("en-US")
                      )  
                    ) 
                  }
                </div>
              </div>
              
            </div>

            <div className={['Middle'].join(' ') }>
              <div className={['Chart-2'].join(' ')}>
                <div className={['CWrapper'].join(' ')}>
                  <div className={["Circle", gain?"gain":"", lost?"lose":"", overdraft?"overdraft":""].join(" ")} 
                    style={{ "--progress":  ( (gain?(percent/100):(1-percent/100)) * 360) +"deg"}}>
                      <div className={["Circle-Inner"].join(" ")} >
                        <div className={['CWrapper', gain?'Gain':( lost?"Lost":"Normal" ) ].join(' ')}> 
                            <FontAwesomeIcon  icon={  
                                (gain)?faCaretUp:((lost)?
                                  faCaretDown:faMinus)  
                              } className={[ 'fa' ].join(' ')}/> 
                            <div className={['Percent'].join(" ")}> 
                                { 
                                  ( percent + "%" )
                                } 
                            </div>
                        </div>
                        <div className={["Scores"].join(" ")}>
                          {   
                            this.props.scores.map( (v,k) => {
                              var rank = "--"
                              if( v.rank !== 0 ) {
                                var score = (20 - (v.rank-1))/20 * 100
                                rank = this.getRank( score )
                              } else {
                                rank = { rank : rank, color : gcolor.gnGreyColor }
                              }
                                return(
                                  <OverlayTrigger
                                    key={k} 
                                    placement={
                                      (k < 2)?
                                      "top":"bottom"
                                    }
                                    overlay={<Tooltip id="button-tooltip-2"> 
                                      {   v.key + " : " + rank.rank } 
                                    </Tooltip>} >
                                    <div className={['box', 'score' ].join(" ")} 
                                      style={ { backgroundColor : (rank.color+"FF") } }>
                                       <div className={['STitle' ].join(" ")} >{ v.key  } </div> 
                                       <div className={['Rank' ].join(" ")} >{ rank.rank } </div>
                                    </div>
                                  </OverlayTrigger>

                                )
                            } )
                          }
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={['Lower'].join(' ') } >
              { purchased &&
                <div className={['Subscribed'].join(' ') } > { 'subscribed' } </div>
              }
              {
                !purchased && this.state.isOwner &&
                <div className={['Owner'].join(' ') } > { 'myportfolio' } </div>
              }

              { (onhold>0) &&
                <div className={['Onhold', (onhold===0)?'Empty':"" ].join(' ') }>
                  { 'onhold_label'  + " : " + onhold  }
                </div>
              }

              {
                !this.state.isOwner && recent &&
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{ "live" }</Tooltip>}
                >
                  <div className={['Recent' ].join(' ') }> {  'is_trading' } </div>
                </OverlayTrigger>
              }

            </div>


          </div>
        )
    }
}


export default Card;


