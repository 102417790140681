import './index.scss';
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class Products extends Component {

  constructor(props) {
    super(props)
    this.state = {
      //selected : props.products[0].key
    }
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Products", (this.props.selected===this.props.id)?"Selected":""].join(" ")} id={this.props.id}>
        
        {
          this.props.products.map( 
            (v, k) => {
              return (
                <div className={["Docker", (this.props.selectedProduct === v.key)?"Selected":"" ].join(" ")} key={k} >
                  <div className={["Mask"].join(" ")} ></div>
                  <div className={["Title"].join(" ")}> 
                    <div className={["Header"].join(" ")}> 
                      <FontAwesomeIcon icon={ v.icon } className={["Fa"].join(" ")} />
                      <div className={["Text"].join(" ")}>{ v.value } </div>

                      <OverlayTrigger 
                        placement={"bottom"}
                        overlay={
                        <Tooltip >
                          <p>{ "Go to " + v.value.toUpperCase() }</p>
                          <p>{ v.domain }</p>
                        </Tooltip>
                      }>
                        <FontAwesomeIcon icon={ faPlay } className={["Play"].join(" ")} onClick={ (e) => {
                          window.open( v.domain, "_blank" )
                        }} />
                      </OverlayTrigger>

                    </div>
                    <div className={["Sub-Title"].join(" ")}> { v.desc } </div>
                  </div>
                  
                  <div className={["Wrapper"].join(" ")}>
                    <v.comp {...this.props} id={ v.key } />
                  </div>
                </div>
              )
            }
          )

        }

        
        


      </div>
    )
  }
}

export default Products;

