import './App.scss';
import React, { Component } from 'react';
import logo from "./public/img/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
//import {Image} from "react-bootstrap"

import Header from "./Components/Header"
import Cover from "./Components/Cover"
import Aboutus from "./Components/Aboutus"
import Products from "./Components/Products"
import Partners from "./Components/Partners"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faLayerGroup, faBowlRice, faKitchenSet, faPlay } from '@fortawesome/free-solid-svg-icons'

import Demi from './Components/Products/Demi'
import Finit from './Components/Products/Finit'
import Gp from './Components/Products/Gp'
import Jumi from './Components/Products/Jumi'

const PRODUCTS = [
  {
    key : "demi", 
    value : "deMi" , 
    icon : faBowlRice, 
    comp : Demi,
    desc : "An extensive platform that allows you to filter stocks, choose your desired trading strategies, and trade at the market. All done in just a few clicks.",
    domain : "https://demiapp.garlican.com"
  },
  { 
    key : "jumi", 
    value : "juMi", 
    icon : faKitchenSet, 
    comp : Jumi,
    desc : "Enable your ideas and turn into an automated trading strategy. No coding requirment.",
    domain : "https://demiapp.garlican.com/manager"
  },
  { 
    key : "gp", 
    value : "Garlic Plate", 
    icon : faMap, 
    comp : Gp,
    desc : "Inspiring from continental drift, plates(sector) movement incurs chain reactions. For sector and signal lovers. GP is a tool focusing on Technical Analytics that catalyzes your decision and response soundness.",
    domain : "https://gp.garlican.com"
  },
  {
    key : "finit", 
    value : "Finit",  
    icon : faLayerGroup, 
    comp : Finit,
    desc : "A filter assisting in Fundamental Analysis. Choose your desired stocks via screening company's financial statement indicators to select your potential holdings.",
    domain : "https://finit.garlican.com"
  }
  
]

const CONTENTS = [
  {key : "header", component : Header},
  {key : "cover", component : Cover},
  {key : "aboutus", component : Aboutus},
  {key : "products", component : Products},
  //{key : "partners", component : Partners},
]

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selected : "cover",
      selectedProduct : PRODUCTS[0].key
    }
    this.updatevalue = this.updatevalue.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.detect = this.detect.bind(this)
  }

  componentDidMount() {
    var _this = this
    var elem = document.getElementById("app")
    elem.onscroll = this.detect
  }

  updatevalue(obj) {
    this.setState(obj)
  }
  
  scrollTo(val, key) {
    //window.scrollTo(  )
    var elem = document.getElementById(val);
    elem.scrollIntoView({behavior: 'smooth'}, false);
    this.setState({
      selected : key?key:val,
    })
  }

  detect(e) {
    function isVisible (ele) {
      const { top, bottom } = ele.getBoundingClientRect();
      const vHeight = (window.innerHeight || document.documentElement.clientHeight);
      return (
        (top > 0 || bottom > 0) &&
        top < vHeight
      );
    }
    var v = JSON.parse( JSON.stringify( CONTENTS ))
    v.splice(0,1)
    //v.reverse()
    for( var i in v) {
      var key = v[i].key
      var elem = document.getElementById( key )
      var bool = isVisible( elem )
      if( bool ) {
        this.setState({ selected : key })
        break
      }
    }
    //console.log(e)
  }


  render() {
    return (
      <div className={["App"].join(" ")} id={"app"} >

        {
          CONTENTS.map( (v,k) => {
            return (
              <v.component 
                key={k} 
                id={ v.key } 
                products={PRODUCTS}
                updatevalue={ this.updatevalue  } 
                selected={ this.state.selected }
                scrollTo={this.scrollTo }
                selectedProduct={this.state.selectedProduct}
              />
            )
          } )
        }

        <div className={ ["Menu"].join(" ") }>
          {
            PRODUCTS.map((v,k) => {
              return(
                <div className={["Holder", (this.state.selected === v.key)?"Selected":"" ].join(" ")} key={k} 
                  onClick={ (e)=>{ 
                      this.setState({ selectedProduct : v.key  }) 
                      var _this = this
                      setTimeout( (e) => {
                        _this.scrollTo(v.key, "products")
                      }, 500 )
                      
                  } } >
                  <FontAwesomeIcon icon={v.icon} className={["Fa"].join(" ")}/>
                  <div className={["Name"].join(" ")}> { v.value } </div>
                </div>
              )
            }  )
          }
        </div>

      </div>
    )
  }
}

export default App;
