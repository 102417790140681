import './index.scss';
import React, { Component } from 'react';
import Card from "./Card"
import {Image} from 'react-bootstrap'

import bg1 from "./img/bg1.jpg"
import bg2 from "./img/bg2.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell,faLayerGroup, faChartLine, faShop  } from '@fortawesome/free-solid-svg-icons'

const CARDS = [
  { key : 1, name : "Robot Portfolio" ,cash : 1500, profit : 200.09,  base : 200, frame : 1500, 
    scores : [
      {  key : "risk_rate" , rank: 3 },
      {  key :'win_rate' , rank: 8 },
      {  key :"quantity_rate" , rank: 5 },
      {  key : "profit_rate" , rank: 4  }
    ]
  },
  { key : 2, name : "My deMi Botfolio", cash : 5000, profit : 522.77, base : 90, frame : 1000,
    scores : [
      {  key : "risk_rate" , rank: 7 },
      {  key :'win_rate' , rank: 10 },
      {  key :"quantity_rate" , rank: 8 },
      {  key : "profit_rate" , rank: 2  }
    ]
  },
  { key : 3, name : "Automated Bot", cash : 3000, profit : 178.91, base : 120, frame : 800, 
    scores : [
      {  key : "risk_rate" , rank: 8 },
      {  key :'win_rate' , rank: 1 },
      {  key :"quantity_rate" , rank: 11 },
      {  key : "profit_rate" , rank: 8  }
    ]
  },
]

const MESSAGES = [
  { key : 1, icon : faBell, title : "Realtime Advice", desc : "Provide realtime buy/sell suggestion on just a click." },
  { key : 2, icon : faLayerGroup , title : "Multiple Stocks", desc : "Screen over 100+ stocks in once." },
  { key : 3, icon : faChartLine , title : "Detail Anaysis", desc : "Comprehensive anaysis report to digonsis each trading portfolio." },
  { key : 4, icon : faShop , title : "Marketplace", desc : "Purchasing brilliant trading ideas from peers. Use it for your own purpose." },
]

class Demi extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Demi" , (this.props.selected==="demi")?"Selected":"" ].join(" ")} id={this.props.id}>
        <div className={["Bg"].join(" ")}> 
          <div className={["Top"].join(" ")}> </div>
          <Image src={bg1} className={["Img", "First"].join(" ")}/>
          <Image src={bg2} className={["Img", "Second"].join(" ")}/>
        </div>
        <div className={["Holder"].join(" ")}>
          {
            CARDS.map( (v,k) => {
              return(
                <div className={["Carrier"].join(" ")} key={k}>
                  <Card 
                    name={v.name}
                    cash={v.cash}
                    profit={v.profit}
                    base={v.base}
                    frame={v.frame}
                    scores={v.scores}
                  />
                </div>
              )
            } )

          }
        </div>

        <div className={["Messages"].join(" ")}> 
          {
            MESSAGES.map( (v, k) => {
              return(
                <div className={["MyHolder"].join(" ")} key={k}>
                  <div className={["Headline"].join(" ")}>
                    <FontAwesomeIcon icon={v.icon} className={["Fa"].join(" ")} />
                    <div className={["HLine"].join(" ")}> {v.title} </div>
                  </div>
                  <div className={["Desc"].join(" ")}> {v.desc} </div>
                </div>
              )
            } )
          }
        </div>
      </div>
    )
  }
}

export default Demi;

