import './index.scss';
import React, { Component } from 'react';


class Partners extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Partners"].join(" ")} id={this.props.id}>

      </div>
    )
  }
}

export default Partners;

