import React, { Component } from 'react';
import './index.scss';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft  } from '@fortawesome/free-solid-svg-icons'



class Roadmap extends Component {

    constructor(props) {
      super(props)
      this.state = {

      }   
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    UNSAFE_componentWillReceiveProps(props) {
    }

    render() {
        return (
          <div className={['Roadmap' ].join(" ") }>  
              
              <div className={['Bottom'].join(" ") }> 
                  {
                    this.props.tree.map( (v,k) => {
                        return (
                        <div 
                          className={['RTitle', 
                            (k > this.props.selectedIndex)?"Not":"",
                            (k === this.props.selectedIndex)?"Selected":"",
                            (k === this.props.tree.length-1)?"Last":"",
                          ].join(" ") } key={ k }> 
                          <FontAwesomeIcon 
                            className={['Fa'].join(" ") } 
                            icon={ v.icon }
                          />
                          <div className={['Name'].join(" ") }>{  (k+1) + "." +  v.key.toUpperCase()  }</div>
                        </div>
                        )
                      }
                    )
                  }
                  <div className={['Road'].join(" ") }></div>
                  <div className={['Road-Cover'].join(" ") } style={ { 
                    width : (100 * (this.props.selectedIndex+1)/(this.props.tree.length) ) + "%"
                  }} ></div>
              </div>


          </div>
        )

    
    }
}


export default Roadmap;



