import './index.scss';
import React, { Component } from 'react';
import {Image} from "react-bootstrap"


import bg from "./img/p.jpg"

import box1 from "./img/box1.jpg"
import box2 from "./img/box2.jpg"
import graph1 from "./img/graph1.jpg"
import graph2 from "./img/graph2.jpg"
import rank from "./img/rank2.jpg"

const IMAGES = [
  { key : "rank", image : rank , desc : "Detect day high and day low" },
  { key : "box1", image : box1 , desc : "Rank the Strength of each sectors" },
  { key : "graph1", image : graph1 , desc : "Check out the trendency of sectors" },
  { key : "box2", image : box2 , desc : "See VCP and Strength of each stock" },
]


class Gp extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Gp"].join(" ")} id={this.props.id}>
        <div className={["Bg"].join(" ")}> 
          <Image src={bg} className={["Img"].join(" ")}/>
        </div>
        <div className={["Container"].join(" ")}> 
          {
            IMAGES.map(
              
              (v,k) => {
                return(
                  <div className={['Box', ("b"+(k+1))  ].join(" ")} key={k}  >
                    <Image src={ v.image } className={["Img"].join(" ")} />
                    <div className={["Msg"].join(" ")}> { v.desc } </div>
                  </div>
                )
              }
            )
          }
        </div>
      </div>
    )
  }
}

export default Gp;

