import './index.scss';
import React, { Component } from 'react';
import {Image} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFilter, faBarsStaggered, faBuildingColumns, faFileInvoice, faTruckFast } from '@fortawesome/free-solid-svg-icons'

import bg from "./img/p.jpg"
import box from "./img/box.jpg"
import box2 from "./img/box2.jpg"

const Messages = [
  { key : 0, value : [
      { key : "global" , icon : faGlobe, desc : "Market availables : Dow Jones, Nasdaq and Hong Kong Excchange etc." },
      { key : "screen" , icon : faFilter, desc : "Consists 100+ Filters including Financial statements, Technical Analysis and Volume studies etc" },
      { key : "multiple" , icon : faBarsStaggered, desc : "Supports up to 10 different conditions." },
    ] 
  }, 
  { key : 1, value : [
      { key : "market" , icon : faBuildingColumns, desc : "8000+ stocks from Dow Jone and Nasdaq. 2000+ from Hong Konf Exchange." },
      { key : "column" , icon : faFileInvoice, desc : "Listing comprehensive data all in one page." },
      { key : "fast" , icon : faTruckFast, desc : "Get your results within 5 seconds." },
    ]  
  }
]


class Finit extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  calcAngleDegrees(x, y) {
    return Math.round(Math.atan2(y, x) * 180 / Math.PI);
  }

  render() {
    return (
      <div className={["Finit"].join(" ")} id={this.props.id}>
        <div className={["Bg"].join(" ")}> 
          <Image src={bg} className={["Img"].join(" ")}/>
        </div>
        <div className={["Container"].join(" ")}> 
          <div className={["Holder"].join(" ")}> 
            <div className={["Box", "b1"].join(" ")} >
                <div className={["i-Container","Left"].join(" ")}>
                  <Image src={box} className={["Img", ].join(" ")} />
                </div>
                <div className={["Message"].join(" ")}> 
                  { 
                    Messages.map( ( v, k) => {
                      return (
                      <div className={["Msg",("Msg"+(k+1))  ].join(" ")} key={k}>
                        {
                          v.value.map( (v1, k1) => {
                            return(
                              <div className={ ["Wrapper"].join(" ") } key={k1}>
                                <FontAwesomeIcon icon={ v1.icon } className={["Fa"].join(" ")} />
                                <div className={["Desc"].join(" ")}>{ v1.desc }</div>
                              </div>
                            )
                          } )
                        }
                      </div>
                      )
                    } )
                  }
                </div>
                <div className={["i-Container","Right"].join(" ")}>
                  <Image src={box2} className={["Img",  ].join(" ")} />
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Finit;

