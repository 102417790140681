import './index.scss';
import React, { Component } from 'react';
import aboutus from '../../public/img/aboutus.jpg'
import {Image} from 'react-bootstrap'
import style from "../../public/style.scss"

class Aboutus extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      isStarted : false
    }

  }

  componentDidMount() {
    var _this = this
  }



  render() {
    return (
      <div className={["Aboutus", (this.props.selected===this.props.id)?"selected":"" ].join(" ")} id={this.props.id}>
        <div className={["Title"].join(" ")}>{"about us"}</div>

        <div className={ ['Messages'].join(" ") }>
          <div className={ ['Msg-Box'].join(" ") }>
            <div className={ ['Frame'].join(" ") }></div>
            <p className={ ['Msg'].join(" ") }>
              {
                "Garlican is a FinTech software company that aims at reinventing and popularizing the retail trading experiences. Founded by a team of software engineers from San Jose and quantitative analysts in HK, Garlican created its trading language Gython in 2019."
              }
            </p>
          </div>
          <div className={ ['Msg-Box'].join(" ") }>
            <div className={ ['Frame'].join(" ") }></div>
            <p className={ ['Msg'].join(" ") }>
              {
                "With its own IDE layers, users may create their own Botfolio (Robo-portfolio) equipped with their own watchlists and algorithimic strategies, empowering users by dealing with risk-controlled, low-capital yet high-volume transactions that are otherwise impossible in traditionally manual settings. "
              }
            </p>
          </div>
          <div className={ ['Msg-Box'].join(" ") }>
            <div className={ ['Frame'].join(" ") }></div>
            <p className={ ['Msg'].join(" ") }>
              {
                "It is Garlican's vision that even a modest trading initiative could fit into a professional investor's philosophy without cumbersome effort. Committed towards a technology-driven framework, Garlican endeavours to build a more computationally-decentralized, resources-sharing (community-based), and organic financial ecosystem accross all classes of demographics."
              }
            </p>
          </div>
        </div>

        { true === false &&
        <div className={["Handshake"].join(" ")}>
          <div  className={["G-Holder"].join(" ")}>
              <svg className={['Hands'].join(" ")} >
                <g id="Layer_x0020_1_client" className={['Frame'].join(" ")}>
                  <metadata id="CorelCorpID_0Corel-Layer_client"/>
                  <g id="_1707362028240_client">
                  <g className="right_boy">
                    <path className="client_fil0" d="M184.8 279.13c0.48,4.29 1.21,12.15 -0.1,18.05 -0.39,1.78 -4.33,1.67 -4.33,1.67 0,0 1.77,-4.62 -4.31,0.85 -6.34,5.7 -16.28,3.51 -17.8,2.6 -1.54,-0.92 -3.34,-2.32 0.31,-5.77 3.13,-2.96 8.38,-5.81 9.6,-8.6 1.21,-2.77 1.58,-11.55 1.58,-11.55l15.04 2.74z"/>
                    <g id="Right_boy_hand">
                    <path className="client_fil1" d="M100.19 118.22c-1.43,0.16 -4.61,-0.16 -7.32,-0.8 -2.7,-0.64 -10.98,0.48 -9.95,1.35 1.04,0.87 0.56,0.87 4.54,1.03 3.98,0.16 3.66,2.23 4.37,2.62 0.46,0.26 -11.41,3.55 -12.19,6.23 -0.55,0.11 -1.04,0.39 -1.25,0.85 -0.38,0.84 0.87,0.8 2.02,1.37 0.02,0.01 0.04,0.02 0.06,0.03 0.02,0.01 0.04,0.02 0.06,0.03 0,0.56 0.4,0.56 2.7,1.11 0.24,0.95 2.78,0.95 3.02,1.27 0.24,0.32 2.39,-0.08 3.42,-0.48 -0.83,-2.56 9.39,-3.8 12.97,-6.68 2.07,-1.67 -2.47,-7.96 -2.47,-7.96z"/>
                    <polygon className="client_fil2" points="105.51,115.33 100.6,118.05 105.11,133.5 111.66,130.01 "/>
                    <path className="client_fil3" d="M153.77 55.4c-8.72,1.63 -20.94,39.79 -23.52,41.51 -2.57,1.72 -27.47,18.4 -27.47,18.4 0,0 4.89,16.67 6.43,20.33 3.31,-0.18 30.16,-17.57 32.74,-19.29 2.58,-1.72 5.25,-32.01 5.25,-32.01l6.57 -28.95z"/>
                    </g>
                    <polygon className="client_fil2" points="155.22,49.07 151.21,52.02 149.19,61.37 155.07,55.74 "/>
                    <path className="client_fil1" d="M168.08 32.44c0.23,3.62 -0.32,8.13 2.72,12.74 3.04,4.62 3.04,4.62 3.04,4.62l-17.44 6.39c0,0 -0.32,-8.2 -3.23,-11.18 -2.91,-2.97 14.91,-12.57 14.91,-12.57z"/>
                    <path className="client_fil1" d="M141.79 10.66c4.88,-8.94 28.61,-11.96 29.94,3.5 2.46,28.17 -15.18,30.85 -15.18,30.85 -5.86,-0.48 -7.37,0.09 -11.53,-1.84 -2.03,-0.93 -3.2,-3.97 -4.22,-8.03 -1.82,-0.52 -3.92,-0.69 -3.64,-2 1.06,-1.73 1.59,-5.03 2.12,-6.29 -0.48,-6.32 0.18,-11.99 2.51,-16.19z"/>
                    <path className="client_fil4" d="M151.57 44.85c-4.61,-0.28 -2.78,0.25 -6.53,-1.49 -2.02,-0.93 -3.18,-3.97 -4.21,-8.03 -1.83,-0.52 -3.93,-0.7 -3.65,-2 1.06,-1.73 1.6,-5.03 2.12,-6.29 -0.47,-6.31 -1.98,-11.24 0.34,-15.43 0.24,-0.45 2.71,-1.63 3.04,-2.03l3.02 0.85c-1.09,10.78 -2.44,26.82 12.34,30.71l5.22 11.76 -7.96 6.18c0,0 -2.36,-10.43 -3.73,-14.22z"/>
                    <path className="client_fil5" d="M170.25 5.99c-1.94,-2.79 -4.59,-5.84 -11.96,-5.56 -7.16,0.27 -11.86,1.42 -16.38,6.47 -2.32,2.55 -0.73,5.5 -0.73,5.5 0,0 4.43,-2.52 6.15,-2.1 1.72,0.41 3.32,3.66 5.69,6.79 2,2.63 0.32,5.7 0.32,5.7 0,0 7.39,2.46 7.6,4.85 0.2,2.4 7.56,7.8 7.56,7.8 0,0 6.28,-8.42 7.4,-13.88 2.63,-12.74 -5.21,-14.89 -5.66,-15.57z"/>
                    <path className="client_fil6" d="M150.56 17.03c-2.36,-3.12 -2.16,-3.45 -3.88,-3.87 -1.72,-0.41 -8.74,2.5 -8.74,2.5 0,0 -2.67,-4.15 -0.33,-6.73 4.53,-5.03 11.12,-8.65 18.3,-8.92 2.89,-0.11 5.39,0.35 7.5,1.12 -3.41,1.35 -9.88,5.33 -8.85,22.04 -1.96,-0.97 -1.35,-0.41 -1.35,-0.41 0,0 -0.63,-3.1 -2.63,-5.73z"/>
                    <path className="client_fil1" d="M156.07 24.68c1.3,-0.93 3.7,-3.72 5.01,-2.15 1.26,1.59 1.02,7.15 -2.06,8.91 -3.05,1.77 -2.95,-6.76 -2.95,-6.76z"/>
                    <path className="client_fil0" d="M168.38 268.95c-1.36,3.82 -0.38,7.93 0.16,13.08 0.53,5.15 1.73,9.04 -3.45,10.22 -5.17,1.17 -30.66,0.62 -30.66,0.62 0,0 -4.58,-6.11 7.03,-7.57 9.01,-1.13 13.7,-9.13 13.43,-16.64 -0.06,-1.93 13.5,0.29 13.5,0.29z"/>
                    <path className="client_fil3" d="M142.54 145.27c0.46,19.04 2.1,72.08 2.43,74.8 3.09,25.52 3.56,59.87 3.56,59.87l18.27 0.45c0,0 6.15,-44 0.48,-62.28 -1.72,-5.55 6.48,-60 6.48,-60l-31.21 -12.84z"/>
                    <path className="client_fil7" d="M150.07 142.6c0.45,19.04 7.18,76.92 8.39,79.37 5.79,11.8 4.64,65.96 4.64,65.96l25.15 0.03c0,0 -2.94,-46.73 -5.48,-69.51 -0.41,-3.62 -0.33,-31.52 1.51,-56.03 1.09,-14.51 -5.12,-29.51 -5.12,-29.51l-29.1 9.68z"/>
                    <path className="client_fil8" d="M184.76 77.44c0.22,-10.12 -0.6,-17.54 -3.52,-21.84 -2.74,-4.05 -7.2,-7.47 -10.48,-10.73 0,0 -7.87,3.99 -12.3,8.22 -1.22,1.17 -7.02,4.86 -8.32,6.49 -3.39,4.23 -7.09,17.44 -9.23,29.9 -2.97,17.24 -0.46,77.56 -0.46,77.56 30.38,4.72 47.79,1.55 47.69,-0.59 -1.44,-31.55 -3.82,-69.12 -3.38,-89.01z"/>
                    <g id="bag">
                    <path className="client_fil9" d="M177.36 171.08l0.21 -9.6 -16.7 -0.36 -0.21 9.6 16.7 0.36zm-17.28 1.75c-0.88,-0.02 -1.57,-0.75 -1.56,-1.63l0.23 -10.65c0.02,-0.88 0.75,-1.57 1.63,-1.56l17.75 0.38c0.88,0.02 1.58,0.74 1.56,1.62l-0.23 10.65c-0.02,0.88 -0.75,1.57 -1.63,1.56l-17.76 -0.38z"/>
                    <path className="client_fil10" d="M197.98 214.79c-0.01,0.39 -0.3,0.69 -0.68,0.69l-58.48 -1.26c-0.37,-0.01 -0.66,-0.33 -0.65,-0.72l0.96 -44.55c0.01,-0.38 0.31,-0.69 0.67,-0.68l58.48 1.26c0.37,0.01 0.66,0.33 0.65,0.72l-0.96 44.54z"/>
                    <path className="client_fil3" d="M199.7 189.41c-0.01,0.38 -0.32,0.69 -0.7,0.68l-60.78 -1.31c-0.38,-0.01 -0.68,-0.33 -0.68,-0.71l0.46 -21.25c0.01,-0.39 0.33,-0.69 0.71,-0.68l60.78 1.3c0.28,0.01 0.53,0.19 0.62,0.44l0 2.44 -0.41 19.08z"/>
                    <polygon className="client_fil11" points="173.59,192.98 163.48,192.77 163.59,187.98 173.7,188.2 "/>
                    </g>
                    <path className="client_fil1" d="M166.17 138.87c0.08,2.44 -1.15,6.55 -1.07,9 0.07,2.43 -3.11,10.99 -2.95,12.48 0.16,1.48 -0.12,3.74 -0.05,5.16 0.18,3.69 2.98,2.56 3.34,0.77 0.13,-0.6 0.14,-0.47 0.14,-0.47 0,0 0.38,1.84 1.59,1.92 1.62,0.12 2.13,-0.76 2.5,-1.91 0.42,1.52 2.79,1.42 3.32,-0.73 1.21,1.59 2.59,0.55 3.3,-2.39 0.47,-1.95 0.22,-11.54 0.11,-14.35 -0.12,-2.82 1.94,-10.74 1.94,-10.74l-12.16 1.25z"/>
                    <polygon className="client_fil12" points="162.16,146.87 162.35,151.84 177.8,153.63 178.57,145.77 "/>
                    <path className="client_fil13" d="M164.81 65.54c1.07,-8.44 10.88,-12.75 16.35,-8.71 5.47,4.04 7.56,32.85 4.95,57.3 0,0 -4.65,36.07 -4.99,38.11 -10.06,-3.13 -21.29,-2.6 -21.29,-2.6l7.71 -44.66 -2.73 -39.44z"/>
                    <polygon className="client_fil2" points="152.03,56.58 148.92,74.09 166.99,51.15 "/>
                    <path className="client_fil13" d="M170.97 44.67l4.35 4.33 -11.58 12.38 -6.42 2.59 0.23 3.64 -15.95 27.54c0,0 5.31,-21.3 9.48,-27.27 4.17,-5.97 19.89,-23.21 19.89,-23.21z"/>
                    <polygon className="client_fil12" points="169.46,39.99 153.41,53.38 156.05,61.92 172.17,45.3 "/>
                  </g>
                  <g className="left_boy">
                    <path className="client_fil9" d="M44.33 185.14l0 -9.11 -15.85 0 0 9.11 15.85 0zm-16.35 2.01c-0.84,0 -1.51,-0.68 -1.51,-1.51l0 -10.11c0,-0.83 0.68,-1.51 1.51,-1.51l16.84 0c0.84,0 1.51,0.67 1.51,1.51l0 10.11c0,0.83 -0.67,1.51 -1.51,1.51l-16.84 0z"/>
                    <path id="Left_boy_bag" className="client_fil14" d="M66.79 226.17c0,0.37 -0.28,0.66 -0.63,0.66l-65.53 0c-0.35,0 -0.63,-0.3 -0.63,-0.66l0 -42.27c0,-0.36 0.28,-0.66 0.63,-0.66l65.53 0c0.35,0 0.63,0.3 0.63,0.66l0 42.27z"/>
                    <polygon className="client_fil2" points="30.28,51.8 38.85,60.15 38.54,65.42 28.46,54.63 "/>
                    <path className="client_fil4" d="M27.42 30.59c-0.19,3.77 -6.56,15.45 -9.67,20.28 -3.11,4.83 -2.88,5.48 -2.88,5.48l18.19 6.47c0,0 3.97,-7.77 6.96,-10.88 3,-3.11 -12.59,-21.35 -12.59,-21.35z"/>
                    <path className="client_fil1" d="M53.04 33.86c-0.97,10.83 -7.21,21.54 -12.69,21.05 -5.48,-0.49 -15.55,-10.27 -14.58,-21.11 0.97,-10.83 7,-20.31 17.13,-17.81 11.06,2.72 11.1,7.02 10.14,17.86z"/>
                    <path className="client_fil6" d="M53.84 16.02c1.86,5 1.02,8.15 -1.55,8.28 -2.55,0.14 -5.06,-0.32 -6.9,1.07 -1.73,1.29 -4.54,1.44 -5.83,2.3 -1.28,0.86 1.22,-4.23 1.22,-4.23l-2.3 3.92 0.54 -3.91 -0.91 1.96 -4.2 2.76 -1.27 5.48 -2.86 1.86 -0.59 -4.16 -2.54 6.6 -5.08 6.74c0,0 -3.41,-6.54 -3.92,-10.13 -0.6,-4.27 -0.1,-5.18 0.12,-7.58 0.28,-3.02 0.37,-3.72 0.85,-6.52 0.52,-3.05 3.44,-7.35 5.49,-7.87 2.04,-0.51 3.28,-0.91 4.29,-0.37 1.02,0.55 1.17,-1.77 4.13,-2.4 2.97,-0.65 2.91,-1.09 7.15,-1 4.24,0.1 7.03,1.54 10.34,3.37 2.19,1.21 3.83,3.82 3.83,3.82z"/>
                    <path className="client_fil5" d="M42.96 34.57l-0.09 0.68 -0.2 1.47 0.01 0.04c-0.02,0.52 0.27,0.95 0.63,1l3.91 0.66 0.15 0.01c0.35,0.01 0.74,-0.34 0.84,-0.87l0.36 -1.99 0.03 -0.19c0.04,-0.55 -0.23,-0.98 -0.6,-1.04l-4.16 -0.72 -0.05 0 -0.01 -0c-0.36,-0 -0.74,0.37 -0.81,0.95zm4.08 5.24l-3.92 -0.65c-0.99,-0.17 -1.65,-1.22 -1.63,-2.41l0.03 -0.32 0.16 -1.37 0.09 -0.69c0.15,-1.29 1.03,-2.29 2.07,-2.28l0.25 0.02 4.18 0.73c1.01,0.19 1.64,1.33 1.52,2.57l-0.04 0.33 -0.39 2.03 -0.57 -0.1 0.57 0.1c-0.23,1.19 -1.12,2.07 -2.09,2.05l-0.22 -0.01z"/>
                    <path className="client_fil5" d="M51.72 35.79l-0.21 1.97 -0.01 0.17c-0.03,0.59 0.12,0.78 0.12,0.78l1.66 0.37 0.05 0c0.01,0.01 0.25,-0.24 0.32,-0.92l0.21 -1.88 0.02 -0.27c0.03,-0.61 -0.11,-0.77 -0.12,-0.77l-1.71 -0.39 0 0.01c-0.01,0 -0.26,0.25 -0.32,0.93zm1.45 4.65l-1.69 -0.37c-0.56,-0.18 -0.74,-1.17 -0.71,-2.11l0.03 -0.4 0.2 -1.93c0.12,-1.05 0.47,-2.06 1.09,-2.15l0.03 0 0.06 0.01 1.71 0.38c0.57,0.17 0.75,1.18 0.71,2.13l-0.03 0.42 -0.21 1.92c-0.13,1.05 -0.48,2.04 -1.09,2.12l-0.09 -0.01z"/>
                    <path className="client_fil5" d="M41.91 33.69c0,0 -16.4,-4.97 -16.84,-4.56 -0.48,0.69 -1.2,1.32 -1.2,1.32l1.89 -0.36 16.15 6.07 0 -2.47z"/>
                    <path className="client_fil6" d="M45.08 45.54c-1.25,0 -3.49,1.07 -4.11,1.62 -0.62,0.54 -4.33,2.85 -4.44,4.76 -0.12,1.9 1.51,5.21 4.32,5.77 0.92,0.18 4.44,0.1 5.39,-2.18 0.41,-1 2.09,-4.86 0.98,-5 -1.1,-0.13 -2.45,-0.19 -2.85,-0.49 -1.05,-0.79 -0.48,-1.49 0.01,-1.49 0.5,0 0.4,0.44 0.77,0.44 0.37,0 3.09,1.56 3.44,1.15 1.54,-1.81 0.98,-3.14 0.98,-3.14l-4.49 -1.43z"/>
                    <path className="client_fil1" d="M29.07 30.56c-1.04,-2.44 -4.11,-2.64 -5.03,-0.75 -0.91,1.92 -0.57,9.06 2.97,10.1 3.53,1.03 2.06,-9.35 2.06,-9.35z"/>
                    <polygon className="client_fil5" points="49.19,35.48 51.38,35.59 51.31,37.36 49,36.99 "/>
                    <polygon className="client_fil12" points="19.61,46.54 21.57,44.7 36.87,60.1 36.57,67.1 "/>
                    <path className="client_fil15" d="M15.26 266.98c1.44,4.35 0.24,8.94 -0.48,14.75 -0.72,5.81 -2.17,10.15 3.63,11.6 5.8,1.45 34.57,1.45 34.57,1.45 0,0 5.32,-6.77 -7.74,-8.7 -10.15,-1.5 -15.23,-10.64 -14.75,-19.1 0.13,-2.16 -15.23,0 -15.23,0z"/>
                    <path className="client_fil3" d="M48.44 152.5c-0.9,19.8 -5.79,58.78 -7.21,69.13 -1.87,13.55 -5.68,60.39 -5.68,60.39l-20.67 -0.41c0,0 -6,-48.63 0.29,-67.51 1.91,-5.72 -5.39,-62.49 -5.39,-62.49l38.66 0.9z"/>
                    <path className="client_fil0" d="M8.52 272.37c1.45,4.35 0.24,8.95 -0.48,14.76 -0.73,5.79 -2.58,13.07 3.4,12.92 7.7,-0.21 34.79,0.13 34.79,0.13 0,0 5.32,-6.76 -7.73,-8.7 -10.15,-1.51 -15.24,-10.63 -14.75,-19.11 0.12,-2.16 -15.24,0 -15.24,0z"/>
                    <path className="client_fil7" d="M42.85 155.15c-0.91,19.77 -5.27,56.34 -8.11,70.52 -2.68,13.4 -4.46,61.2 -4.46,61.2l-25.94 0.91c0,0 3.5,-49.76 4.09,-69.65 0.37,-12.73 -0.13,-24.6 -0.84,-43.13 -0.11,-2.84 -2.33,-7.6 -2.43,-10.13 -0.38,-8.96 2.03,-15.4 2.03,-15.4l35.67 5.68z"/>
                    <path className="client_fil16" d="M50.14 158.39l0.03 -22.19c0,0 -1.63,-26.38 -4.32,-44.37 -1.95,-12.99 -6.1,-21.1 -9.53,-25.56 -1.32,-1.71 -1.72,-2.97 -2.97,-4.21 -4.5,-4.49 -17.26,-9.86 -17.26,-9.86 -7.08,6.74 -12.23,12.97 -12.23,33.66 0,20.67 3.38,25.57 2.71,33.66 -1.12,13.44 -4.36,29.15 -4.66,40.09 0,0 48.64,5.27 48.23,-1.22z"/>
                    <g id="left_boy_hand">
                    <path className="client_fil4" d="M71 117.7c3.7,0.95 6.44,1.31 7.52,1.31 1.07,0 10.86,0.95 11.69,1.31 0.84,0.36 6.44,7.16 6.92,7.99 0.48,0.83 0,2.39 -2.39,1.79 0,0 -0.72,2.03 -2.98,1.07 -0.48,1.43 -2.15,1.91 -2.51,1.79 -0.36,0.48 -2.27,1.07 -2.87,0.72 -0.59,-0.36 -3.58,-3.34 -4.53,-3.58 -0.96,-0.24 -5.49,-1.43 -7.64,-2.51 -2.15,-1.07 -6.44,-0.03 -6.92,-0.27 -0.47,-0.24 3.7,-9.63 3.7,-9.63z"/>
                    <path className="client_fil8" d="M21.92 63.54c7.47,1.48 18.23,32.46 22.94,36.38 1.83,1.53 19.42,11.08 30.39,14.22 3.08,0.88 -4.51,21.18 -4.51,21.18 0,0 -33.68,-14.93 -38.83,-19.22 -7.06,-5.89 -12.79,-16.55 -14.71,-20.89 -6.22,-14.05 -12.06,-35.01 4.71,-31.68z"/>
                    </g>
                    <path className="client_fil8" d="M14.21 53.15l5.4 -6.61c0,0 12.15,13.67 14.5,15.02 8.61,11.13 9.63,20 10.3,22.03 -1.35,-1.35 -8.85,-11.88 -8.85,-11.88l-0.16 -5.88 -4.84 -0.23 -16.35 -12.44z"/>
                    <polygon className="client_fil8" points="24.66,135.52 41.53,131.59 41.14,140.22 23.49,144.93 "/>
                  </g>
                  </g>
                </g>
              </svg>



          </div>
        </div>
        }

        <div className={['Truck'].join(" ")}>
            <div className="mountain"></div>
            <div className="hill"></div>
            <div className="tree"></div>
            <div className="tree"></div>
            <div className="tree"></div>
            <div className="rock"></div>
            <div className="truck"></div>
            <div className="wheels"></div>
        </div>

        <div className="Bg">
          <canvas id="c"></canvas>
        </div>


      </div>
    )
  }
}

export default Aboutus;

