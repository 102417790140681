import './index.scss';
import React, { Component } from 'react';
import { faKitchenSet, faCopy, faShop, faCircleCheck  } from '@fortawesome/free-solid-svg-icons'
import {Image} from 'react-bootstrap'
import Roadmap from './Roadmap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import bg1 from "./img/bg1.jpg"
import bg2 from "./img/bg2.jpg"

var tree = [
  { key : 'recipe', value : 'Recipe', icon : faKitchenSet  },
  { key : 'copy', value : 'Copytrade', icon : faCopy },
  { key : 'shop', value : 'Launch your bot', icon : faShop },
  { key : 'sucess', value : 'Success', icon : faCircleCheck },
]

const MESSAGES = [
  { key : 1, icon : faKitchenSet, title : "Your trading idea", desc : "Transform your trding strategies into bots. Test your recipe and put it into real trade." },
  { key : 2, icon : faCopy , title : "Copytrade", desc : "Copy a trading template or conrtibute a trading template. Create a botforlio in a minute." },
  { key : 3, icon : faShop  , title : "Launch your bot", desc : "Publish your Bot onto the marketplace (deMi). Earning from selling botfolio ideas." },
  { key : 4, icon : faCircleCheck , title : "Successful business", desc : "Turn your trading idea into a botfolio. Own your business on selling ideas." },
]

var t = null

class Jumi extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedIndex : 0
    }
  }

  componentDidMount() {
    var _this = this
    t = setInterval(
      (e) => {
        var d = new Date()
        d = d.getTime()
        var r = ( Math.round(d/1000) % tree.length)
        _this.setState( {
          selectedIndex : r
        })
      }, 1000
    )
  }

  render() {
    return (
      <div className={["Jumi"].join(" ")} id={this.props.id}>
        
        <div className={["Bg"].join(" ")}> 
          <div className={["Top"].join(" ")}> </div>
          <Image src={bg1} className={["Img", "First"].join(" ")}/>
          <Image src={bg2} className={["Img", "Second"].join(" ")}/>
        </div>

        <div className={["RFrame"].join(" ")} >
          <Roadmap tree={ tree } selectedIndex={this.state.selectedIndex}/>
        </div>


        <div className={["Messages"].join(" ")}> 
          {
            MESSAGES.map( (v, k) => {
              return(
                <div className={["MyHolder", (k===this.state.selectedIndex)?"Selected": ""  ].join(" ")} key={k}>
                  <div className={["Headline"].join(" ")}>
                    <FontAwesomeIcon icon={v.icon} className={["Fa"].join(" ")} />
                    <div className={["HLine"].join(" ")}> {v.title} </div>
                  </div>
                  <div className={["Desc"].join(" ")}> {v.desc} </div>
                </div>
              )
            } )
          }
        </div>

      </div>
    )
  }
}

export default Jumi;

