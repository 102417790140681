import './index.scss';
import React, { Component } from 'react';
//import logo from "../../public/img/logo.png"
import video from "../../public/video/video.mp4"
import ReactPlayer from 'react-player'


const ELEM = [
  {key : "aboutus" , value : "aboutus"},
  {key : "products" , value : "products"},
  {key : "partners" , value : "partners"},
]


class Header extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Cover"].join(" ")} id={this.props.id}>
          <div id="bg" className={["Bg"].join(" ")} ></div>
          <div className={["Title"].join(" ")}> 
            <div className={["Logo"].join(" ")}> 
              <p className={["Clip"].join(" ")} />
              <p className={["Clip", "Border"].join(" ")} />
              <div></div>
            </div>
            <div className={["Text"].join(" ")}>{"ARLICAN"}</div>
          </div>

          <div className={['Msg-Holder'].join(" ")}>
            <div className="dropping-texts">
              <div className={['Msg'].join(" ")}>Tech your Invest</div>
              <div className={['Msg'].join(" ")}>Accelerate your Success</div>
              <div className={['Msg'].join(" ")}>Smart your Money</div>
            </div>
          </div>

          <div className={["Copyright"]}>Garlican Tech Limited &copy; copyrighted 2024</div>
          
          <div className={["Moving-title"].join(" ")}> 
            <div className={["Point"].join(" ")}>
              <div className={["Star"].join(" ")}></div>
            </div>
            <div className={["R-Wrapper"].join(" ")}>
              <div className={["Rail"].join(" ")}></div>
            </div>
          </div>
          <ReactPlayer 
            controls={true}
            url={video} 
            muted={true}
            className={['Video'].join(" ")} 
            playing={true} 
            loop={true} />
        </div>
    )
  }
}

export default Header;

