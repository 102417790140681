import './index.scss';
import React, { Component } from 'react';


const ELEM = [
  {key : "cover" , value : "home"},
  {key : "aboutus" , value : "aboutus"},
  {key : "products" , value : "products"},
  //{key : "partners" , value : "partners"},
]


class Header extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var _this = this
  }

  render() {
    return (
      <div className={["Header", (this.props.selected!=="cover")?"Bg":"" ].join(" ")} id={this.props.id} >
        {
          ELEM.map( (v,k) => {
            return(
              <div key={k} className={["Item", (this.props.selected===v.key)?"selected":"" ].join(" ")} onClick={ (e) => {
                this.props.scrollTo( v.key )
              } }>
                <div className={["Text"].join(" ")}> { v.value } </div>
              </div>
            )
          } )
        }
      </div>
    )
  }
}

export default Header;

